var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('ApolloQuery',{attrs:{"query":require('../graphql/Breweries.gql'),"variables":{}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ref_result = ref.result;
var error = ref_result.error;
var data = ref_result.data;
var isLoading = ref.isLoading;
return [(isLoading)?_c('div',{staticClass:"loading apollo"},[_vm._v("Loading...")]):(error)?_c('div',{staticClass:"error apollo"},[_vm._v("An error occured")]):(data)?_c('div',{staticClass:"result apollo"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":data.breweries,"search":_vm.search,"sort-by":"name","items-per-page":20,"footer-props":{
            itemsPerPageOptions: [20, 30, 50, -1],
          }},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
          var item = ref.item;
return [_c('v-btn',{attrs:{"plain":"","to":'/brewery/' + item.id}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.device_count",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.devices.length)+" ")]}},{key:"item.vessel_count",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.vessels.length)+" ")]}},{key:"item.batch_count",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.batches.length)+" ")]}}],null,true)})],1):_c('div',{staticClass:"no-result apollo"},[_vm._v("No result :(")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }