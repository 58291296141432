
























































import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {},
  computed: {},
  data: () => ({
    search: "",
    headers: [
      {
        text: "Name",
        align: "start",
        value: "name",
      },
      { text: "Allocated Devices", value: "devices.length", align: "end" },
    ],
  }),
  methods: {},
})
export default class Breweries extends Vue {}
