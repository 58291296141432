
















import { Component, Vue } from "vue-property-decorator";
import Login from "@/components/okta-login.vue"; // @ is an alias to /src
// import Breweries from "@/components/add-brewery.vue"; // @ is an alias to /src
import BreweryList from "@/components/breweries-list.vue"; // @ is an alias to /src

@Component({
  components: {
    Login,
    // Breweries,
    BreweryList,
  },
  computed: {},
  data: () => ({
    selected: null,
  }),
  methods: {},
})
export default class Home extends Vue {}
